import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';



function NotFoundPage() {
  // const { logoImg } = useStaticQuery(
  //   graphql`
  //     query {
  //       logoImg: file(relativePath: { eq: "logo/Logo_Becher.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 200, maxHeight: 200, quality: 100) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }
  //     }
  //   `
  // );
  return (
    <Layout>
        <SEO
          keywords={[`Sigma Bar Würzburg`, `Würzburg`, `Bar`, `Trinkspiele`]}
          title="404: Not Found"
        />
      <div className="text-center">

        <h2 className="bg-red text-2xl font-bold inline-block my-8 p-3">
          Looks like this page is a ghost that got abducted by aliens...
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
